<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-2">
          <template #start>
           
              <Dropdown
                id="status"
                v-model="submissionstatus"
                :options="submissionItems"
                :value="submissionItems.value"
                optionLabel="name"
                placeholder="Select Year"
                :class="{ 'p-invalid': submitted && !submissionstatus }"
                required="true"
                v-on:change="get_list"
              ></Dropdown>
             
              <!-- <Button
                icon="pi pi-search"
                class="p-button-success mr-2"
                @click="get_list"
              /> -->
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->

              <!-- <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="refresh"
              /> -->
           
          </template>

          <template #end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Create"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="openNew"
            />
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
       
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
              :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>List of Schedule Lecture</b> </h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>
          
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>

          <Column
            header="Batch Year"
            :field="columns[6]"
            style="min-width: 5rem;text-align: right;"
          >
            <template #body="{ data }">
              {{ data.batchYear }}
            </template>
          </Column>
          <Column header="Schedule" :field="columns[1]">
            <template #body="{ data }">
            <span v-if="data.schedule=='' || data.schedule==null || data.schedule==undefined ">-</span>
              <span v-else>{{ data.schedule[0].schedule }}</span>
             
            </template>
          </Column>
          <Column header="Teacher Name" :field="columns[1]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.teacherName }}
            </template>
          </Column>
          <Column header="cource" :field="columns[5]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.cource }}
            </template>
          </Column>
          <Column header="Subject" :field="columns[2]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.subject }}
            </template>
          </Column>

          <Column header="Topic" :field="columns[3]">
            <template #body="{ data }">
              {{ data.topic }}
            </template>
          </Column>
          <Column header="View Student" :field="columns[4]">
            <template #body="{ data }">

            <span v-if="data.schedule=='' || data.schedule==null || data.schedule==undefined ">-</span>
              <span v-else>  
             


              <Button
                label="View"
                class="p-button-outlined"
                @click="view_std(data)"
              />
              </span>
            </template>
          </Column>

          <Column headerStyle="min-width:5rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-model:visible="onlineclassDialog"
          :style="{ width: '800px', width: '800px' }"
          header="Form on Teacher Lecture Schedule"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <Calendar
                  v-model="onlineclassdata.schedule"
                  :showIcon="true"
                  :showTime="true"
                  hourFormat="12"
                />

                <label for="calendar"><b>Schedule From</b></label>
              </span>
            </div>

            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <Calendar
                  v-model="onlineclassdata.schedulE_TO"
                  :showIcon="true"
                  :showTime="true"
                  hourFormat="12"
                />

                <label for="calendar"><b>Schedule TO</b></label>
              </span>
            </div>
          </div>

          <div class="grid">
            <div class="field col-12 md:col-3">
              <h5>Streaming Type</h5>
              <div class="field-radiobutton">
                <RadioButton
                  id="city1"
                  name="stype"
                  value="Live"
                  v-model="stype"
                />
                <label for="city1">Live</label>
              </div>
              <div class="field-radiobutton">
                <RadioButton
                  id="city2"
                  name="stype"
                  value="RECORDED"
                  v-model="stype"
                />
                <label for="city2">RECORDED</label>
              </div>
            </div>
            <div class="field col-12 md:col-9">
              <div class="field" v-if="stype == 'RECORDED'">
                <label for="enrollmenT_YEAR">Recorded Streaming</label>
                <InputText
                  id="enrollmenT_YEAR"
                  v-model.trim="onlineclassdata.embeD_LINK"
                  required="true"
                  autofocus
                />
              </div>

              <div class="field" v-if="stype == 'RECORDED'">
                <label for="enrollmenT_YEAR">Live Chat Link</label>
                <InputText
                  id="enrollmenT_YEAR"
                  v-model.trim="onlineclassdata.embeD_CHAT"
                  required="true"
                  autofocus
                />
              </div>
            </div>
          </div>

          <div class="grid">
            <div class="field col-12 md:col-12">
              <div class="field">
                <label for="notification">Discussion On Topic </label>
                <Textarea
                  id="notification"
                  v-model.trim="onlineclassdata.discuessioN_ON_TOPIC"
                  required="true"
                  autofocus
                />
              </div>
            </div>
          </div>

          <div class="grid" v-if="show_ui == true">
            <div class="field col-12 md:col-1">
              <h5>Publish</h5>
              <div class="field-checkbox">
                <Checkbox v-model="checked" :binary="true" />
              </div>
            </div>
            <div class="field col-12 md:col-11">
              <div class="field">
                <label for="enrollmenT_YEAR">Recorded Video</label>
                <InputText
                  id="enrollmenT_YEAR"
                  v-model.trim="onlineclassdata.storeD_LINK"
                  required="true"
                  autofocus
                />
              </div>
            </div>
          </div>

          <div class="grid" v-if="show_ui == true">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <Calendar
                  v-model="onlineclassdata.publisheD_ON"
                  :showIcon="true"
                  :showTime="true"
                  hourFormat="12"
                  
                />

                <label for="calendar"><b>Published On</b></label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  
                  autofocus
                  v-model="onlineclassdata.publisheD_BY"
                />
                <label for="inputtext">Publish By</label>
              </span>
            </div>
          </div>
          <hr />
          <div class="grid" v-if="show_ui == true">
            <div class="field col-12 md:col-4">
              <h5>Study Material</h5>
              <FileUpload
                mode="basic"
                id="file_attachmentsm"
                ref="file_attachmentsm"
                name="demo[]"
                accept="application/pdf,image/jpeg,image/png"
                :maxFileSize="1000000"
                @change="handleFileUploadsm"
              />
            </div>
            <div class="field col-12 md:col-4">
              <h5>Upload Assignment</h5>
              <FileUpload
                mode="basic"
                id="file_attachmentUA"
                ref="file_attachmentUA"
                name="demo[]"
                accept="application/pdf,image/jpeg,image/png"
                :maxFileSize="1000000"
                @change="handleFileUploadUA"
              />
            </div>
            <div class="field col-12 md:col-4">
              <h5>Upload Answer</h5>
              <FileUpload
                mode="basic"
                id="file_attachmentUANS"
                ref="file_attachmentUANS"
                name="demo[]"
                accept="application/pdf,image/jpeg,image/png"
                :maxFileSize="1000000"
                @change="handleFileUploadUANS"
              />
            </div>
          </div>

          <hr />
          <h5 class="m-0" v-if="product.tscH_ID">Audit Information</h5>

          <DataTable
            v-if="product.tscH_ID"
            :value="online_class"
            style="font-size: 12px"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            v-model:filters="filters2"
            filterDisplay="menu"
            :loading="loading2"
            :filters="filters2"
            responsiveLayout="scroll"
            :totalRecords="online_class.length"
          >
            <Column
              header="Created On"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.createD_ON == null ||
                    data.createD_ON == '' ||
                    data.createD_ON == undefined
                  "
                  >-</span
                >
                <span v-else>{{ data.createD_ON }}</span>
              </template>
            </Column>
            <Column
              header="Created By"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.createD_BY == null ||
                    data.createD_BY == '' ||
                    data.createD_BY == undefined
                  "
                  >-</span
                >
                <span v-else>{{ data.createD_BY }}</span>
              </template>
            </Column>
            <Column
              header="Updated On"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.updateD_ON == null ||
                    data.updateD_ON == '' ||
                    data.updateD_ON == undefined
                  "
                  >-</span
                >
                <span v-else>{{ data.updateD_ON }}</span>
              </template>
            </Column>
            <Column
              header="Updated By"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.updateD_BY == null ||
                    data.updateD_BY == '' ||
                    data.updateD_BY == undefined
                  "
                  >-</span
                >
                <span v-else>{{ data.updateD_BY }}</span>
              </template>
            </Column>
          </DataTable>
          <hr />
          <div class="grid">
            <div class="col-12 md:col-3"></div>
            <div class="col-12 md:col-3">
              <Button
                v-if="onlineclassdata.tscH_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="schedulelectureliveadd"
              />
              <Button
                v-else
                label="Create"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="schedulelectureliveadd"
              />
            </div>
            <div class="col-12 md:col-3">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
              />
            </div>
            <div class="col-12 md:col-3"></div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '1000px' }"
          header="Form on Teacher Live Video Lecture"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-2">
              <div class="field">
                <label for="status">Batch Year</label>
                <Dropdown
                  id="status"
                  v-model="submissionstatus"
                  :options="submissionItems"
                  :value="submissionItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !submissionstatus }"
                  required="true"
                ></Dropdown>
              </div>
            </div>

            <div class="col-12 md:col-3">
              <div class="field">
                <label for="status">Batch</label>
                <Dropdown
                  id="status"
                  v-model="coursestatus"
                  :options="courseItems"
                  :value="courseItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  v-on:change="update_sub(coursestatus.value)"
                  :class="{ 'p-invalid': submitted && !coursestatus }"
                  required="true"
                ></Dropdown>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="field">
                <label for="status">Teacher</label>
                <Dropdown
                  id="status"
                  v-model="teacherstatus"
                  :options="teacherItems"
                  :value="teacherItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  v-on:change="get_sub(teacherstatus.value)"
                  :class="{ 'p-invalid': submitted && !teacherstatus }"
                  required="true"
                ></Dropdown>
              </div>
            </div>
            <div class="col-12 md:col-3">
              <div class="field">
                <label for="status">Subject</label>
                <Dropdown
                  id="status"
                  v-model="subjectstatus"
                  :options="subjectItems"
                  :value="subjectItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  v-on:change="get_topic(subjectstatus.value)"
                  :class="{ 'p-invalid': submitted && !subjectstatus }"
                  required="true"
                ></Dropdown>
              </div>
            </div>

            <div class="col-12 md:col-12">
              <div class="field">
                <label for="status">Topic</label>
                <Dropdown
                  id="status"
                  v-model="topicstatus"
                  :options="topicItems"
                  :value="topicItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>
            </div>

            <div class="col-12 md:col-12">
              <div class="field">
                <label for="topic">Topic</label>
                <Textarea
                  id="topic"
                  v-model.trim="product.topic"
                  required="true"
                  autofocus
                  rows="5"
                  :class="{
                    'p-invalid': submitted && !product.topic,
                  }"
                />
              </div>
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-3"></div>
            <div class="col-12 md:col-3">
              <Button
                v-if="product.tscH_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="schedulelectureeditadd"
              />
              <Button
                v-else
                label="Create"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="schedulelectureeditadd"
              />
            </div>
            <div class="col-12 md:col-3">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
            <div class="col-12 md:col-3"></div>
          </div>
          <hr />

          <h5 class="m-0" v-if="product.tscH_ID">Online Class Sessions</h5>
          <div class="col-12 md:col-2" v-if="product.tscH_ID">
            <!-- <Button
                label="Create Live"
                icon="pi pi-times"
                class="p-button-success mr-1 mb-1"
                @click="productDialog = false"
              /> -->
            <Button
              label="Create Live"
              class="p-button-outlined"
              @click="addOnlineClass"
            />
          </div>
          <DataTable
            v-if="product.tscH_ID"
            :value="online_class"
            style="font-size: 12px"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            v-model:filters="filters2"
            filterDisplay="menu"
            :loading="loading2"
            :filters="filters2"
            responsiveLayout="scroll"
            :totalRecords="online_class.length"
          >
            <Column headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success mr-2"
                  @click="editOnlineClass(slotProps.data)"
                />
              </template>
            </Column>

            <Column
              header="schedule"
              style="min-width: 2rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.schedule }}
              </template>
            </Column>
            <Column
              header="Discussion On Topic"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.discuessioN_ON_TOPIC }}
              </template>
            </Column>
            <Column
              header="Stored Video"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.storeD_LINK == null ||
                    data.storeD_LINK == '' ||
                    data.storeD_LINK == undefined
                  "
                  >-</span
                >
                <span v-else
                  ><Button
                    label="View"
                    class="p-button-outlined"
                    @click="view_video(data.storeD_LINK)"
                /></span>
                <!-- {{ data.storeD_LINK }} -->
              </template>
            </Column>
            <Column
              header="Study Material"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.uploaD_CONTENT == null ||
                    data.uploaD_CONTENT == '' ||
                    data.uploaD_CONTENT == undefined
                  "
                  >-</span
                >
                <span v-else
                  ><Button
                    label="Download"
                    class="p-button-outlined"
                    @click="view_video(data.uploaD_CONTENT)"
                /></span>

                <!-- {{ data.uploaD_CONTENT }} -->
              </template>
            </Column>
            <Column
              header="Assignment to do"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.uploaD_ASSIGNMENT == null ||
                    data.uploaD_ASSIGNMENT == '' ||
                    data.uploaD_ASSIGNMENT == undefined
                  "
                  >-</span
                >
                <span v-else
                  ><Button
                    label="Download"
                    class="p-button-outlined"
                    @click="view_video(data.uploaD_ASSIGNMENT)"
                /></span>
                <!-- {{ data.uploaD_ASSIGNMENT }} -->
              </template>
            </Column>
            <Column
              header="Answer By Teacher"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.uploaD_ANSWER == null ||
                    data.uploaD_ANSWER == '' ||
                    data.uploaD_ANSWER == undefined
                  "
                  >-</span
                >
                <span v-else
                  ><Button
                    label="Download"
                    class="p-button-outlined"
                    @click="view_video(data.uploaD_ANSWER)"
                /></span>
                <!-- {{ data.uploaD_ANSWER }} -->
              </template>
            </Column>
            <Column
              header="Upload DPP On"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.uploaD_ANSWER_ON == null ||
                    data.uploaD_ANSWER_ON == '' ||
                    data.uploaD_ANSWER_ON == undefined
                  "
                  >-</span
                >
                <span v-else>{{ data.uploaD_ANSWER_ON }}</span>
              </template>
            </Column>
          </DataTable>

          <hr />
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="stdDialog"
          :style="{ width: '800px', width: '800px' }"
          header="Student Register For Live Class"
          :modal="true"
          class="p-fluid"
        >
          <DataTable
            :value="stdproducts"
            style="font-size: 12px"
            :paginator="true"
            class="p-datatable-gridlines"
            :rows="5"
            dataKey="id"
            :rowHover="true"
            v-model:filters="filters1"
            filterDisplay="menu"
            :loading="loading1"
            :filters="filters1"
            responsiveLayout="scroll"
            :totalRecords="stdproducts.length"
          >
            <Column header="name" style="min-width: 6rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.studentname }}
              </template>
            </Column>
            <Column header="mobile" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.mobile }}
              </template>
            </Column>
            <Column header="email" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.email }}
              </template>
            </Column>
            <Column
              header="register"
              style="min-width: 3rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.register }}
              </template>
            </Column>
            <Column header="attend" style="min-width: 3rem" :field="columns[0]">
              <template #body="{ data }">
                {{ data.attend }}
              </template>
            </Column>
            <Column
              header="attendON"
              style="min-width: 6rem"
              :field="columns[0]"
            >
              <template #body="{ data }">
                {{ data.attendON }}
              </template>
            </Column>
          </DataTable>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      get_url:"",
      show_ui: true,
      checked: false,
      stype: null,
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },

      coursestatus: { name: "", value: "" },
      courseItems: [],

      teacherstatus: { name: "", value: "" },
      teacherItems: [],

      subjectstatus: { name: "", value: "" },
      subjectItems: [],

      topicstatus: { name: "", value: "" },
      topicItems: [],

      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentUANS: "",
      file_attachmentUA: "",
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],
      online_class: null,
      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      onlineclassDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      onlineclassdata: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
    this.get_course_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    async schedulelectureliveadd() {

      if(!this.onlineclassdata.schedule)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Schedule From",
            life: 3000,
          });
          return false;
    }
    if(!this.onlineclassdata.schedulE_TO)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Schedule TO",
            life: 3000,
          });
          return false;
    }
    if(!this.stype)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Streaming Type",
            life: 3000,
          });
          return false;
    }
    if(!this.onlineclassdata.discuessioN_ON_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Discussion On Topic",
            life: 3000,
          });
          return false;
    }
      

      var From_D = new Date(this.onlineclassdata.schedule);
      var To_D = new Date(this.onlineclassdata.schedulE_TO);
      var published = "";
      if (this.checked == true) {
        published = "YES";
      } else {
        published = "NO";
      }

      //edit
      if (this.onlineclassdata.tscH_ID) {

        var d = new Date();
                var file_name =
                  d.getTime() +
                  "_" +
                  d.getMilliseconds() +
                  "_" +
                  this.file_attachmentsm.name;
        //----------------------------------------------
        var b = new Date();
                var file_name2 =
                  b.getTime() +
                  "_" +
                  b.getMilliseconds() +
                  "_" +
                  this.file_attachmentUA.name;
        //--------------------------------------------------
        var c = new Date();
                var file_name3 =
                  c.getTime() +
                  "_" +
                  c.getMilliseconds() +
                  "_" +
                  this.file_attachmentUANS.name;



        var data = {
          tscH_ID: this.product.tscH_ID,
          schedule: From_D.toLocaleString(),
          schedulE_TO: To_D.toLocaleString(),
          storeD_LINK: this.onlineclassdata.storeD_LINK,

          discuessioN_ON_TOPIC: this.onlineclassdata.discuessioN_ON_TOPIC,
          createD_BY: this.onlineclassdata.createD_BY,
          updateD_ON: this.onlineclassdata.updateD_ON,
          updateD_BY: localStorage.getItem("full_name"),
          uploaD_ASSIGNMENT: file_name2,
          uploaD_ANSWER: file_name3,
          published: published,
          publisheD_ON: this.onlineclassdata.publisheD_ON,
          publisheD_BY: this.onlineclassdata.publisheD_BY,

          livE_TYPE: this.stype,
          embeD_LINK: this.onlineclassdata.embeD_LINK,
          embeD_CHAT: this.onlineclassdata.embeD_CHAT,
          uploaD_CONTENT: file_name,
          uploaD_ASSIGNMENT_ON: this.onlineclassdata.uploaD_ASSIGNMENT_ON,
          uploaD_ASSIGNMENT_BY: this.onlineclassdata.uploaD_ASSIGNMENT_BY,
        };
        this.isLoadingModel = true;
        var promise = apis.schedulelectureliveedit(data);
        promise
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.onlineclassDialog = false;

            //upload document
            if (responseapi.data.status == true) {
              //------------------------------------------------
              if (this.file_attachmentsm.name) {
                

                var file_data = {
                  filePath: "pt_assign/" + file_name,
                  contentType: this.file_attachmentsm.type,
                };

                var promise5 = apis.uploadFile(file_data);
                promise5.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentsm, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentsm.type,
                      },
                    })
                    .then((response) => {});
                });
              }
              //------------------------------------------------
              if (this.file_attachmentUA.name) {
                

                var file_data2 = {
                  filePath: "pt_assign/" + file_name2,
                  contentType: this.file_attachmentUA.type,
                };

                var promise6 = apis.uploadFile(file_data2);
                promise6.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentUA, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentUA.type,
                      },
                    })
                    .then((response) => {});
                });
              }

              // //-------------------------------------------------
              if (this.file_attachmentUANS.name) {
                

                var file_data3 = {
                  filePath: "pt_assign/" + file_name3,
                  contentType: this.file_attachmentUANS.type,
                };

                var promise7 = apis.uploadFile(file_data3);
                promise7.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentUANS, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentUANS.type,
                      },
                    })
                    .then((response) => {});
                });
              }
              //-------------------------------------------------
            }
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.onlineclassDialog = false;
            this.$swal.fire(error.response.data);
          });
      }
      //add----------------------------------------------------------
      else {
        if (this.stype == "Live") {
          this.onlineclassdata.embeD_LINK = null;
          this.onlineclassdata.embeD_CHAT = null;
        }

        var data1 = {
          tscH_ID: this.product.tscH_ID,
          schedule: From_D.toLocaleString(),
          schedulE_TO: To_D.toLocaleString(),
          livE_TYPE: this.stype,
          embeD_LINK: this.onlineclassdata.embeD_LINK,
          embeD_CHAT: this.onlineclassdata.embeD_CHAT,
          discuessioN_ON_TOPIC: this.onlineclassdata.discuessioN_ON_TOPIC,
          updateD_BY: localStorage.getItem("full_name"),
          createD_BY: localStorage.getItem("id"),
          updateD_ON: this.onlineclassdata.updateD_ON,

          storeD_LINK: null,
          uploaD_ASSIGNMENT: null,
          uploaD_ANSWER: null,
          published: null,
          publisheD_ON: null,
          publisheD_BY: null,
          uploaD_CONTENT: null,
          uploaD_ASSIGNMENT_ON: null,
          uploaD_ASSIGNMENT_BY: null,
        };
        this.isLoadingModel = true;
        var promises = apis.schedulelectureliveadd(data1);
        promises
          .then((responseapi) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.onlineclassDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.onlineclassDialog = false;
            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
    },
    get_course_list: function () {
      var data = {
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };

      var promise = apis.batchlist(data, this.id);
      promise.then((response) => {
        console.log(response);

        var info = response.data;
        if (info) {
          this.courseItems = [];
          for (var i = 0; i < info.length; i++) {
            this.courseItems.push({
              name: info[i].batch,
              value: info[i].mjmB_ID,
            });
          }
        }
      });
    },

    view_video(video) {
     alert(video)
      window.open(this.get_url+video, "_blank");
    },
    view_std(info) {

   
      var data = {
        TSCH_DE_ID: info.schedule[0].tscH_DE_ID ,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];
    },
    handleFileUploadUA() {
      this.file_attachmentUA = this.$refs.file_attachmentUA.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploadUANS() {
      this.file_attachmentUANS = this.$refs.file_attachmentUANS.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.submissionstatus.value) {
        var data = {
          //id:parseInt(localStorage.getItem("id")),

          count: true,
          year: this.submissionstatus.value,
        };
        this.loading = true;
        var promise = apis.schedulelecturelist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.submissionstatus.value) {
        var data = {
          // id:parseInt(localStorage.getItem("id")),
          Year: this.submissionstatus.value,
          page_no: this.page_no,
          limit: this.limit,
          count: false,
        };
        this.loading = true;
        var promise = apis.schedulelecturelist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
       this.submissionstatus = { name: "", value: "" };
       this.coursestatus = { name: "", value: "" };
       this.teacherstatus = { name: "", value: "" };
       this.get_submission_dropdown();
       this.get_course_list();
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async schedulelectureeditadd() {
      this.submitted = true;

    if(!this.submissionstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Batch Year",
            life: 3000,
          });
          return false;
    }

    if(!this.coursestatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Batch",
            life: 3000,
          });
          return false;
    }

    if(!this.teacherstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Teacher",
            life: 3000,
          });
          return false;
    }

    if(!this.subjectstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Subject",
            life: 3000,
          });
          return false;
    }

    if(!this.topicstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Topic",
            life: 3000,
          });
          return false;
    }

    if(!this.product.topic)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Topic",
            life: 3000,
          });
          return false;
    }










      //edit
      if (this.product.tscH_ID) {
        var data = {
          TSCH_ID: this.product.tscH_ID,
          TEACHER_ID: this.teacherstatus.value.toString(),
          SUBJECT: this.subjectstatus.value,
          BATCH: this.coursestatus.value,
          TOPIC: this.topicstatus.value,
          TOPIC_DESCRIPTION: this.product.topic,
          CREATED_BY: localStorage.getItem("full_name"),
          YEAR: this.submissionstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.schedulelectureedit(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add
      else {
        var data1 = {
          TEACHER_ID: this.teacherstatus.value.toString(),
          SUBJECT: this.subjectstatus.value,
          BATCH: this.coursestatus.value,
          TOPIC: this.topicstatus.value,
          TOPIC_DESCRIPTION: this.product.topic,
          CREATED_BY: localStorage.getItem("full_name"),
          YEAR: this.submissionstatus.value,
        };
        this.isLoadingModel = true;
        var promise1 = apis.schedulelectureadd(data1);
        promise1
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },

    get_topic(MJMS_ID) {
      this.topicstatus = { name: "", value: "" };

      var data = {
        MJMS_ID: parseInt(MJMS_ID),
      };

      var promise = apis.getsubjecttopic(data, this.id);
      promise.then((response) => {
        var info = response.data;
        //alert(info.length)
        if (info) {
          this.topicItems = [];
          for (var i = 0; i < info.length; i++) {
            this.topicItems.push({
              name: info[i].name,
              value: info[i].code,
            });
          }
        }
      });
    },

    get_sub(Teacher_id) {
      this.subjectstatus = { name: "", value: "" };

      var data = {
        Teacher_id: parseInt(Teacher_id),
      };

      var promise = apis.teachersubjects(data, this.id);
      promise.then((response) => {
        var info = response.data;
        //alert(info.length)
        if (info) {
          this.subjectItems = [];
          for (var i = 0; i < info.length; i++) {
            this.subjectItems.push({
              name: info[i].name,
              value: info[i].code,
            });
          }
        }
      });
    },
    update_sub(batch) {
      this.teacherstatus = { name: "", value: "" };

      var data = {
        batch: parseInt(batch),
      };

      var promise = apis.getcourceteacherlist(data, this.id);
      promise.then((response) => {
        var info = response.data;
        //alert(info.length)
        if (info) {
          this.teacherItems = [];
          for (var i = 0; i < info.length; i++) {
            this.teacherItems.push({
              name: info[i].name,
              value: info[i].code,
            });
          }
        }
      });
    },
    Schedule_Lecture_View(product) {
      this.isLoading = true;
      
      
      var data = {
        tscH_ID: product.tscH_ID,
      };

      var promise = apis.schedulelectureview(data, this.id);
      promise.then((response) => {
        var view = response.data;
        if (response.data.liveLectureDetails) {
          this.online_class = response.data.liveLectureDetails;
        }
        this.coursestatus.value = view.batch;
        this.coursestatus.name = view.batchName;
        this.get_url="";
        this.get_url=view.urlfile;
      
        this.get_sub(view.teacheR_ID);
        this.update_sub(view.batch);
        this.get_topic(view.subject);

        this.teacherstatus.value = view.teacheR_ID;
        this.teacherstatus.name = view.teachername;

        this.subjectstatus.value = view.subject;
        this.subjectstatus.name = view.subjectname;

        this.topicstatus.value = view.topic;
        this.topicstatus.name = view.topicname;

        this.productDialog = true;
      });
      this.isLoading = false;
    },
    async editOnlineClass(data) {
      this.onlineclassdata = { ...data };
      this.show_ui = true;
      if (this.onlineclassdata.published) {
        if (
          this.onlineclassdata.published == "Yes" ||
          this.onlineclassdata.published == "YES"
        ) {
          this.checked = true;
        }
      }

      if (this.onlineclassdata.livE_TYPE) {
        this.stype = this.onlineclassdata.livE_TYPE;
      }

      this.file_attachment = "";

      this.onlineclassDialog = true;
    },
    async addOnlineClass() {
      this.onlineclassdata = {};
      this.checked = true;
      this.stype = 'RECORDED';
      this.show_ui = false;
      this.onlineclassDialog = true;
    },

    async editProduct(product) {
      this.product = { ...product };
      
      this.Schedule_Lecture_View(this.product);
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
